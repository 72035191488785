import { useState, useRef, useEffect, useId, type FormEvent, useCallback } from 'react';
import { useRouter } from 'next/router';
import { createStore } from '@xstate/store';
import { useSelector } from '@xstate/store/react';
import { Form } from 'react-aria-components';
import { SHA256 } from 'crypto-js';

import { subscribeNewsletter, updateNewsletter } from 'actions/general';
import { useI18n } from 'context/i18n';
import { useAppData } from 'context/app';
import { useIsMounted, useLink, useLocale } from 'hooks';
import { useCopyToClipboard } from 'hooks/useCopyToClipboard';

import { Dialog, DialogHeader, DialogRoot, DialogX } from 'components/ui/dialog';
import { CopySVG } from 'components/iconsSVG/copy';
import { CheckSVG } from 'components/iconsSVG/check';
import { Button } from 'components/ui/button';
import { getContrastingColor } from 'utils/colors';
import { TextField } from 'components/ui/text-field';
import { ProtectionDataModal } from 'components/protectionDataModal';
import { Checkbox } from 'components/ui/checkbox';
import { PrivacyPolicyText } from 'components/legalTexts';
import { Select, SelectItem } from 'components/ui/select';
import { DateField } from 'components/ui/date-field';
import { RadioGroup, Radio } from 'components/ui/radio-group';
import { Link } from 'components/ui/link';
import { Label } from 'components/ui/field';

import { getNextQueryValue } from 'utils/common';
import { dataLayerHandleEvent } from 'utils/dataLayers';
import { getDomainValue } from 'utils/domain';
import { logError } from 'utils/error';
import { isBlank } from 'utils/StringUtils';
import { DEFAULT_BASE_URL } from 'utils/constants/system';
import { getServerError } from 'utils/request';
import { parseEmail } from 'utils/validation';
import { getCalendarDate } from 'utils/date';
import { setCookieBonds } from 'utils/helpers';
import { Drawer, DrawerHeader, DrawerRoot } from 'components/ui/drawer';
import { storage } from 'utils/web-storage';
import { LOCAL_STORAGE_KEYS } from 'utils/constants/storage';

type CmsTextField = {
  text?: string;
  href?: string;
  style: {
    color: string;
    fontSize: string;
    fontFamily: string;
  };
  className?: string;
};

type NewsletterCmsData = {
  allowedUsers: 'all' | 'new';
  dataEnrichment: boolean;
  showVoucher: boolean;
  campaign?: { id?: string; name?: string };
  style: {
    color: string;
    background: string;
  };
  client: {
    camper: boolean;
    camperlab: boolean;
  };
  emailStep: {
    image: {
      url: string;
      mimeType: string;
      fileName: string;
      tags: Array<string>;
      metadata: {
        pixelWidth: number;
        pixelHeight: number;
        imageQuality: number;
        description: string;
      };
    };
    title?: CmsTextField;
    subtitle?: CmsTextField;
    submit?: CmsTextField;
  };
  extraStep: {
    title?: CmsTextField;
    subtitle?: CmsTextField;
    next?: CmsTextField;
    submit?: CmsTextField;
  };
  thanksStep: {
    title?: CmsTextField;
    subtitle?: CmsTextField;
    subscribed?: CmsTextField;
    voucher?: CmsTextField;
    ctaTitle?: CmsTextField;
    ctas?: CmsTextField[];
  };
};

type NewsletterConfig = {
  camperlab: boolean;
  campaign: string | null;
  origin: string;
  allowedUsers: 'all' | 'new';
  dataEnrichment: boolean;
  showVoucher: boolean;
  formColor: 'white' | 'black';
};

type Step = 'initial' | 'email' | 'update' | 'thanks' | 'subscribed';

const store = createStore({
  context: {
    init: false,
    step: 'initial' as Step,
    open: false,
    campaign: '',
    email: '',
    showLinks: true,
    subscribed: false,
    voucher: '',
    origin: '',
  },
  on: {
    init: (
      context,
      event: {
        pathname: string;
        ctas: { url: string }[];
        campaign: string;
        voucher?: string;
      },
    ) => {
      context.init = true;
      context.campaign = event.campaign;
      if (!isBlank(event.voucher)) {
        context.voucher = event.voucher;
      }
      const windowUrl = new URL(event.pathname, DEFAULT_BASE_URL);
      const urlInCtas = event.ctas.some((source) => {
        const sourceUrl = new URL(source.url, DEFAULT_BASE_URL);
        return sourceUrl.pathname.endsWith(windowUrl.pathname);
      });
      context.showLinks = !urlInCtas;
    },
    open: (context, event: { origin: string; direct: boolean }) => {
      const isSubscribed = storage.local.get(LOCAL_STORAGE_KEYS.USER_SUBSCRIBED) === true;

      dataLayerHandleEvent({
        event: 'impression_acquisition_form',
        event_category: ACQUISITION_CATEGORY,
      });

      // maybe
      dataLayerHandleEvent({
        event: 'acquisition_popup',
        info_type: 'impression',
        campaign_detail: context.campaign,
        page_type: window.gtmType || '',
      });

      context.origin = event.origin;
      context.subscribed = isSubscribed;
      // si detectamos el userhash en cookies, estamos suscritos y mostramos el step de suscrito
      if (isSubscribed) {
        context.step = 'subscribed';
      } else if (getDomainValue({ camper: true, nnormal: true, camperlab: false }) || event.direct) {
        // si estamos en camper o nnormal siempre abrimos el step de email, en camperlab he dejado la opcion de ir directo al email y no pasar por el step inicial
        context.step = 'email';
      }
      context.open = true;
    },
    close: (context) => {
      // side effect
      dataLayerHandleEvent({
        event: 'close_acquisition_popup',
        event_category: ACQUISITION_CATEGORY,
        event_detail_1: acquisitionDetail[context.step],
      });
      if (['initial', 'email', 'update'].includes(context.step)) {
        context.step = 'initial';
        context.email = '';
      }
      context.open = false;
    },
    update_user: (context, event: { email: string; subscribed: boolean }) => {
      context.email = event.email;
      context.subscribed = event.subscribed;
    },
    next: (context, event: { step: Step }) => {
      context.step = event.step;
    },
  },
});

const defaultPictureURL = getDomainValue({
  nnormal: '/assets-new/img/newsletter/camper-newsletter-default',
  camper: 'https://www.camper.com/img/BLF',
  camperlab: 'https://www.camperlab.com/img/BLF',
});
const ACQUISITION_CATEGORY = 'acquisition';

const acquisitionDetail: Record<Step, string> = {
  initial: 'acquisition_start', // nuevo
  email: 'acquisition_form',
  update: 'enrichment_form',
  thanks: 'enrichment_end',
  subscribed: 'acquisition_end', // nuevo
};

type PictureProps = {
  newsletterImages: any[];
};

function Picture({ newsletterImages }: PictureProps) {
  const date = Date.now();

  const id = useId();
  let imgSrc = `${defaultPictureURL}.jpg?d=${date}`;
  let sources = [
    {
      type: 'image/avif',
      srcSet: `${defaultPictureURL}.avif?d=${date}`,
    },
    {
      type: 'image/webp',
      srcSet: `${defaultPictureURL}.webp?d=${date}`,
    },
  ];

  if (Array.isArray(newsletterImages) && newsletterImages.length > 0) {
    const jpgImage = newsletterImages.find((picture) => picture.fileName.includes('jpg')) ?? newsletterImages[0];
    imgSrc = `${jpgImage.url}${jpgImage.fileName}?d=${date}`;
    sources = newsletterImages.map((picture) => ({
      type: picture.tags[0],
      srcSet: `${picture.url}${picture.fileName}?d=${date}`,
    }));
  }

  return (
    <picture className="h-full w-full">
      {sources.map((source, index) => (
        <source key={`${id}-nw-img-source-${index}`} type={source.type} srcSet={source.srcSet} />
      ))}
      <img src={imgSrc} alt="Image of newsletter photo" className="h-full w-full object-cover" />
    </picture>
  );
}

type EmailStepProps = {
  cmsData?: NewsletterCmsData['emailStep'];
  config: NewsletterConfig;
};

function onInvalidForm(e: any, step: Step) {
  // get errors from native event
  const target = e.target;
  const hasError = e.target.validity.valid === false;
  if (hasError) {
    dataLayerHandleEvent({
      event: `error_${acquisitionDetail[step]}`,
      event_category: ACQUISITION_CATEGORY,
      event_detail_1: 'validation error',
      event_detail_2: target.name.toLowerCase() ?? '',
    });
  }
}

function onFormSubmit(step: Step) {
  dataLayerHandleEvent({
    event: `try_${step === 'update' ? 'enrichment' : 'acquisition'}_form`,
    event_category: ACQUISITION_CATEGORY,
  });
}

type InitialStepProps = {
  cmsData: NewsletterCmsData['emailStep'];
  config: NewsletterConfig;
};
function InitialStep({ cmsData, config }: InitialStepProps) {
  const { t } = useI18n();
  const { country } = useLocale();
  const { newsletter } = useAppData();

  if (getDomainValue({ camper: true, nnormal: true, camperlab: false })) {
    return null;
  }
  return (
    <div className="mt-5 flex flex-col gap-2">
      <p
        className="mb-0 text-sm lg:text-xs"
        dangerouslySetInnerHTML={{
          __html: cmsData?.subtitle?.text ?? newsletter?.text ?? t('header.messages', `header.ribbon.01.${country.toLowerCase()}.text`),
        }}
      />
      <Button
        variant="solid"
        color={config.formColor === 'white' ? 'inverted' : 'primary'}
        style={cmsData?.submit?.style}
        className={cmsData?.submit?.className}
        onPress={() => store.send({ type: 'next', step: 'email' })}
      >
        <span dangerouslySetInnerHTML={{ __html: cmsData?.submit?.text ?? t('generico', 'subscribe', 'Subscribe') }}></span>
      </Button>
    </div>
  );
}

function EmailStep({ config, cmsData }: EmailStepProps) {
  const { t } = useI18n();
  const { locale, country } = useLocale();
  const { newsletter } = useAppData();
  const isMounted = useIsMounted();
  const step = useSelector(store, (state) => state.context.step);
  const [formStatus, setFormStatus] = useState<'idle' | 'pending'>('idle');
  const [formError, setFormError] = useState<string | null>(null);
  const hasBeenFocused = useRef(false);

  const subtitle = cmsData?.subtitle?.text ?? newsletter?.text ?? t('header.messages', `header.ribbon.01.${country.toLowerCase()}.text`);

  const handleEmailFocus = () => {
    if (hasBeenFocused.current === false) {
      dataLayerHandleEvent({
        event: 'start_acquisition_form',
        event_category: ACQUISITION_CATEGORY,
      });
      hasBeenFocused.current = true;
    }
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (formStatus === 'pending') return;
    try {
      setFormError(null);
      setFormStatus('pending');
      const values = Object.fromEntries(new FormData(e.currentTarget));
      const email = values.email as string;

      const payload: Record<string, unknown> = {
        email,
        profile: locale,
        origin: config.origin,
        campaign: config.campaign,
        lab: config.camperlab,
      };

      const result = await subscribeNewsletter(payload);
      const userSubscribed = result?.contactAlreadySubscribed === true;
      store.send({ type: 'update_user', email, subscribed: userSubscribed });

      // primero lanzamos el evento de error de usuari suscrito
      if (userSubscribed && config.allowedUsers === 'new') {
        dataLayerHandleEvent({
          event: `error_${acquisitionDetail[step]}`,
          event_category: ACQUISITION_CATEGORY,
          event_detail_1: 'e-mail is already subscribed',
          event_detail_2: 'email',
        });
      }
      if (!userSubscribed) {
        dataLayerHandleEvent({
          event: 'generate_lead',
          event_category: 'lead',
        });
      }

      dataLayerHandleEvent({
        event: 'success_acquisition_form',
        event_category: ACQUISITION_CATEGORY,
      });
      if (!userSubscribed) {
        dataLayerHandleEvent({
          event: 'acquisition_popup',
          info_type: 'subscription',
          campaign_detail: newsletter.title.toLowerCase() || '',
          page_type: window.gtmType || '',
        });
      }
      dataLayerHandleEvent({
        event: 'mailShadowed',
        mailSHA256: SHA256(email).toString(),
        mailVanilla: values?.email,
      });
      storage.local.set(LOCAL_STORAGE_KEYS.USER_SUBSCRIBED, true);

      // si tenemos data enrichment vamos a ese paso, si no, checkeamos subscribed de api y vamos a subscribed o thanks
      const nextStep =
        config.dataEnrichment && !userSubscribed ? 'update'
        : userSubscribed ? 'subscribed'
        : 'thanks';

      store.send({ type: 'next', step: nextStep });
    } catch (error) {
      const responseErrorData = error?.data !== undefined ? error.data : error;
      const errorMessage = getServerError(responseErrorData);

      setFormError(errorMessage.message);
      logError(errorMessage.message);
    } finally {
      if (isMounted()) {
        setFormStatus('idle');
      }
    }
  };

  return (
    <div className="flex flex-grow flex-col text-left nnormal:grid nnormal:grid-cols-1 md:nnormal:-m-10 nnormal:md:grid-cols-[355px,355px]">
      <div className="overflow-hidden empty:hidden max-md:hidden">
        {getDomainValue({ nnormal: <Picture newsletterImages={cmsData?.image ? [cmsData.image] : []} />, camper: null, camperlab: null })}
      </div>
      <div className="items-left flex flex-grow flex-col justify-around lab:px-2.5 md:px-5 md:nnormal:px-10 nnormal:md:py-10">
        <div>
          {/* <div className="flex justify-center">
            <Icon />
          </div> */}
          <p
            className={`mb-4 text-2xl nnormal:font-secondary camper:font-moderne camper:font-black lab:hidden md:text-3xl ${cmsData?.title?.className || ''}`}
            style={getDomainValue({ camper: cmsData?.title?.style, nnormal: cmsData?.title?.style, camperlab: undefined })}
            dangerouslySetInnerHTML={{
              __html: cmsData?.title?.text ?? newsletter?.title ?? t('header.messages', `header.ribbon.01.${country.toLowerCase()}.title`, 'Camper Newsletter'),
            }}
          />
          <p
            className={`mb-0 text-sm empty:hidden lab:font-bold md:text-base lab:md:text-xs ${cmsData?.subtitle?.className || ''}`}
            style={getDomainValue({
              camper: cmsData?.subtitle?.style,
              nnormal: cmsData?.subtitle?.style,
              camperlab: undefined,
            })}
            dangerouslySetInnerHTML={{ __html: subtitle }}
          />
        </div>
        <Form
          onSubmit={handleSubmit}
          onInvalid={(e) => onInvalidForm(e, step)}
          className="mt-5 flex flex-col items-start gap-4 lab:flex-grow lab:items-stretch lab:justify-between lab:pb-2.5"
        >
          <div className="flex flex-col items-start gap-4">
            <div className="w-full camper:sm:w-2/3">
              <TextField
                label={t('generico', 'input.email.placeholder', 'Enter your email')}
                hiddenLabel={getDomainValue({ camper: true, nnormal: true, camperlab: false })}
                name="email"
                type="email"
                className="self-stretch"
                validate={(value) => {
                  const valid = parseEmail(value).success;
                  return valid ? true : t('validation', 'required.email', 'Please input your email');
                }}
                onFocus={handleEmailFocus}
                inputProps={{
                  color: config.formColor,
                  variant: getDomainValue({ camper: 'transparent', nnormal: 'transparent', camperlab: 'default' }),
                  placeholder: t('generico', 'input.email.placeholder', 'Enter your email'),
                }}
              />
            </div>
            <div className="lab:my-4" style={{ color: config.formColor }}>
              <ProtectionDataModal origin="newsletter" />
            </div>
            <Checkbox name="privacy" isRequired boxClassName="size-5" color={config.formColor}>
              <PrivacyPolicyText
                className={`text-xs camper:text-xxs lab:text-sm lab:lg:text-xs [&_a]:font-medium ${config.formColor === 'white' ? 'text-inverted [&_a]:!text-inverted' : ''}`}
              />
            </Checkbox>
          </div>

          <p className={`text-xs ${config.formColor === 'white' ? 'text-inverted' : 'text-error'} empty:hidden`}>{formError}</p>
          <div className="mt-2">
            <Button
              type="submit"
              isPending={formStatus === 'pending'}
              variant={getDomainValue({ camper: 'outline', nnormal: 'outline', camperlab: 'solid' })}
              color={config.formColor === 'white' ? 'inverted' : 'primary'}
              style={cmsData?.submit?.style}
              onPress={() => onFormSubmit(step)}
              className={`${cmsData?.submit?.className} lab:w-full`}
            >
              <span dangerouslySetInnerHTML={{ __html: cmsData?.submit?.text ?? t('generico', 'subscribe', 'Subscribe') }}></span>
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
}

type UpdateStepProps = {
  cmsData: NewsletterCmsData['extraStep'];
  config: NewsletterConfig;
};

function UpdateStep({ cmsData, config }: UpdateStepProps) {
  const {
    t,
    profileData: { phonePrefixes },
  } = useI18n();
  const { locale, country: initialCountry, language: initialLanguage } = useLocale();
  const step = useSelector(store, (state) => state.context.step);
  const email = useSelector(store, (state) => state.context.email);
  const subscribed = useSelector(store, (state) => state.context.subscribed);

  const isMounted = useIsMounted();
  const [formStatus, setFormStatus] = useState<'idle' | 'pending'>('idle');
  const [formError, setFormError] = useState<string | null>(null);

  const hasBeenFocused = useRef(false);

  const handleUpdateFocus = () => {
    if (hasBeenFocused.current === false) {
      dataLayerHandleEvent({
        event: 'start_enrichment_form',
        event_category: ACQUISITION_CATEGORY,
      });
      hasBeenFocused.current = true;
    }
  };

  const handleSubmitUpdate = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      const values = Object.fromEntries(new FormData(e.currentTarget)) as Record<string, string>;
      setFormError(null);
      setFormStatus('pending');

      const payload: Record<string, unknown> = {
        email,
        phonePrefix: values.prefix,
        profile: locale,
        origin: config.origin,
        campaign: config.campaign,
        lab: config.camperlab,
        countryId: values.country,
        languageId: values.language,
        birthDate: values.date,
        telephone: values.telephone,
        gender: values.gender,
      };

      await updateNewsletter(payload);

      dataLayerHandleEvent({
        event: 'success_enrichment_form',
        event_category: ACQUISITION_CATEGORY,
      });

      store.send({ type: 'next', step: subscribed ? 'subscribed' : 'thanks' });
    } catch (error) {
      const responseErrorData = error?.data !== undefined ? error.data : error;
      const errorMessage = getServerError(responseErrorData);
      setFormError(errorMessage.message);
      logError(error);
    } finally {
      if (isMounted()) {
        setFormStatus('idle');
      }
    }
  };

  return (
    <div className="flex flex-grow flex-col justify-between gap-4 lab:justify-start lab:px-2.5 lab:pb-2.5">
      <div>
        <p
          style={cmsData?.title?.style}
          className={`mb-0 font-moderne-fat text-2xl nnormal:font-secondary lab:hidden lab:text-sm md:text-3xl lab:lg:text-xs ${cmsData?.title?.className || ''}`}
          dangerouslySetInnerHTML={{
            __html:
              cmsData?.title.text ??
              t(
                'generico',
                getDomainValue({
                  camper: 'camper.newsletter.agradecimiento',
                  nnormal: 'newsletter.agradecimiento',
                  camperlab: 'camperlab.newsletter.agradecimiento',
                }),
                'Thank you for your subscription!',
              ),
          }}
        />
        <p
          style={cmsData?.subtitle?.style}
          className={`mb-0 max-w-[275px] text-sm empty:hidden md:max-w-[438px] md:text-base lab:md:text-sm lab:lg:text-xs ${cmsData?.subtitle?.className ?? ''}`}
          dangerouslySetInnerHTML={{ __html: cmsData?.subtitle?.text }}
        />
      </div>
      <Form onSubmit={handleSubmitUpdate} onInvalid={(e) => onInvalidForm(e, step)} className="flex flex-col gap-4 lab:flex-grow lab:justify-between lab:gap-2">
        <div className="flex flex-col gap-4 lab:gap-2">
          <input type="hidden" name="country" value={initialCountry} />
          <input type="hidden" name="language" value={initialLanguage} />
          <div className="camper:sm:w-2/3">
            <DateField
              name="date"
              label={t('generico', 'input.fecha.nacimiento', 'Date of birth')}
              dateProps={{
                variant: getDomainValue({ camper: 'transparent', nnormal: 'transparent', camperlab: 'default' }),
                color: config.formColor,
              }}
              onFocus={handleUpdateFocus}
              maxValue={getCalendarDate()}
            />
          </div>
          <div>
            <Label className="mb-0 lab:hidden" aria-hidden>
              {t('generico', 'telefono', 'Telephone')}
            </Label>
            <div className="flex lab:flex-col lab:gap-2 camper:sm:w-2/3">
              <Select
                label={t('generico', 'prefijo.telefono', 'Telephone')}
                hiddenLabel={getDomainValue({ camper: true, nnormal: true, camperlab: false })}
                name="preffix"
                items={phonePrefixes as { id: string; name: string }[]}
                defaultSelectedKey={initialCountry}
                variant={getDomainValue({ camper: 'transparent', nnormal: 'transparent', camperlab: 'default' })}
                color={config.formColor}
                onFocus={handleUpdateFocus}
                className="flex-shrink-0"
              >
                {(item) => (
                  <SelectItem key={item.id} id={item.id}>
                    {item.name}
                  </SelectItem>
                )}
              </Select>
              <TextField
                name="telephone"
                label={t('generico', 'telefono', 'Telephone')}
                hiddenLabel={getDomainValue({ camper: true, nnormal: true, camperlab: false })}
                type="tel"
                className="flex-grow"
                onFocus={handleUpdateFocus}
                inputProps={{
                  placeholder: t('generico', 'placeholder.telefono'),
                  color: config.formColor,
                  variant: getDomainValue({ camper: 'transparent', nnormal: 'transparent', camperlab: 'default' }),
                }}
              />
            </div>
          </div>
          <div className="flex flex-col gap-2 text-sm camper:flex-row camper:items-center">
            <p className="mb-0 lab:hidden">{t('newsletter', 'genero')}</p>
            <RadioGroup
              onFocus={handleUpdateFocus}
              hiddenLabel={getDomainValue({ camper: true, camperlab: false, nnormal: true })}
              label={t('newsletter', 'genero')}
              name="gender"
              orientation="horizontal"
            >
              <Radio color={config.formColor} value="W">
                {t('generico', 'input.mujer', 'Women')}
              </Radio>
              <Radio color={config.formColor} value="M">
                {t('generico', 'input.hombre', 'Men')}
              </Radio>
              {getDomainValue({
                nnormal: (
                  <Radio color={config.formColor} value="">
                    {t('generico', 'input.other')}
                  </Radio>
                ),
                camper: null,
                camperlab: null,
              })}
            </RadioGroup>
          </div>
        </div>
        <div className="mt-1 flex flex-col items-start justify-start gap-3 lab:items-stretch">
          <p className={`text-xs ${config.formColor === 'white' ? 'text-inverted' : 'text-error'} empty:hidden`}>{formError}</p>
          <Button
            type="submit"
            isPending={formStatus === 'pending'}
            variant={getDomainValue({ camper: 'outline', nnormal: 'outline', camperlab: 'solid' })}
            color={config.formColor === 'white' ? 'inverted' : 'primary'}
            onPress={() => onFormSubmit(step)}
            style={cmsData?.submit?.style}
          >
            <span dangerouslySetInnerHTML={{ __html: cmsData?.submit?.text ?? t('generico', 'boton.actualizar', 'Update') }} />
          </Button>
          <Button
            onPress={() => store.send({ type: 'next', step: subscribed ? 'subscribed' : 'thanks' })}
            variant={getDomainValue({ camper: 'none', nnormal: 'none', camperlab: 'outline' })}
            type="button"
            className={getDomainValue({ camper: 'text-xs', nnormal: 'text-sm', camperlab: undefined })}
            style={cmsData?.next?.style}
          >
            <span
              className="nnormal:underline camper:underline"
              dangerouslySetInnerHTML={{ __html: cmsData?.next?.text ?? t('generico', 'boton.cancelar', 'Cancel') }}
            />
          </Button>
        </div>
      </Form>
    </div>
  );
}

type ThanksStepProps = {
  cmsData?: NewsletterCmsData['thanksStep'];
  config: NewsletterConfig;
};

function ThanksStep({ cmsData, config }: ThanksStepProps) {
  const {
    t,
    profileData: { emptyBagCollections },
  } = useI18n();
  const to = useLink();
  const step = useSelector(store, (state) => state.context.step);
  const showLinks = useSelector(store, (state) => state.context.showLinks);
  const subscribed = useSelector(store, (state) => state.context.subscribed);

  const { state, onCopy } = useCopyToClipboard(cmsData?.voucher?.text ?? '');

  const handleClickCollectionButton = (collectionName: string) => {
    dataLayerHandleEvent({
      event: 'select_enrichment_end_promotion',
      event_category: 'acquisition',
      event_detail_1: collectionName.toLowerCase(),
    });
  };

  useEffect(() => {
    if (['thanks', 'subscribed'].includes(step) && !isBlank(cmsData?.voucher?.text)) {
      const canSetVoucher = config?.allowedUsers === 'all' || (config?.allowedUsers === 'new' && !subscribed);
      if (canSetVoucher) {
        setCookieBonds(cmsData?.voucher?.text);
      }
    }
  }, [step, cmsData?.voucher?.text, config?.allowedUsers, subscribed]);

  const linkColor = step === 'subscribed' && config.formColor === 'white' ? 'inverted' : 'primary';

  const ctas = Array.isArray(cmsData?.ctas) && cmsData.ctas.length > 0 ? cmsData.ctas : null;
  const canShowAddVoucherMessage = config?.allowedUsers === 'all' || (config?.allowedUsers === 'new' && !subscribed);

  return (
    <div className="flex flex-grow flex-col gap-2.5 lab:justify-between lab:px-2.5 lab:pb-2.5">
      <div className="flex flex-grow flex-col justify-around gap-4 lab:flex-grow-0">
        {step === 'thanks' ?
          <div>
            <p
              style={cmsData?.title?.style}
              className={`mb-0 font-moderne-fat text-2xl nnormal:font-secondary lab:text-sm md:text-3xl lab:lg:text-xs ${cmsData?.title?.className || ''}`}
              dangerouslySetInnerHTML={{ __html: cmsData?.title?.text ?? t('generico', 'thank.you.newsletter') }}
            />
            <p
              style={cmsData?.subtitle?.style}
              className={`mb-0 mt-2 text-sm empty:hidden lab:font-bold md:text-base lab:md:text-xs ${cmsData?.subtitle?.className || ''}`}
              dangerouslySetInnerHTML={{ __html: cmsData?.subtitle?.text ?? t('generico', 'see.you.soon.newsletter') }}
            />
          </div>
        : null}
        {step === 'subscribed' ?
          <div>
            <p
              style={cmsData?.subscribed?.style}
              className="mb-0 text-sm empty:hidden lab:font-bold md:text-base lab:md:text-xs"
              dangerouslySetInnerHTML={{ __html: cmsData?.subscribed?.text ?? t('newsletter', 'user.subscribed') }}
            />
          </div>
        : null}
        <div>
          {config.showVoucher && cmsData?.voucher?.text ?
            <Button variant="outline" color={linkColor} onPress={onCopy}>
              {cmsData?.voucher?.text}
              {state === 'idle' ?
                <CopySVG className="w-6" />
              : <CheckSVG className="w-6" />}
            </Button>
          : null}
          {getDomainValue({ camper: true, camperlab: true, nnormal: false }) && showLinks && !ctas ?
            <>
              <p
                className="mb-0 mt-2 text-xs nnormal:mb-1 nnormal:mt-3 nnormal:text-sm"
                style={cmsData?.ctaTitle?.style}
                dangerouslySetInnerHTML={{ __html: cmsData?.ctaTitle?.text ?? t('generico', 'thank.you.cta') }}
              />
              <div className="my-2.5 grid grid-cols-2 gap-3">
                {emptyBagCollections?.map((item) => (
                  <Link
                    key={`collectionButton-${item.title}`}
                    href={isBlank(item.url) ? undefined : to(item.url)}
                    onPress={() => handleClickCollectionButton(item.id)}
                    variant="outline"
                    color={linkColor}
                  >
                    {item.title}
                  </Link>
                ))}
              </div>
            </>
          : null}
          {showLinks && ctas ?
            <>
              <p
                style={cmsData?.ctaTitle?.style}
                className="mb-0 mt-2 text-xs nnormal:mb-1 nnormal:mt-3 nnormal:text-sm"
                dangerouslySetInnerHTML={{ __html: cmsData?.ctaTitle?.text ?? t('generico', 'thank.you.cta') }}
              />
              <div className="my-2.5 grid grid-cols-2 gap-3">
                {ctas.map((item) =>
                  !isBlank(item.text) ?
                    <Link
                      variant="outline"
                      key={`collectionButton-${item.text}`}
                      href={isBlank(item.href) ? undefined : to(item.href)}
                      style={item.style}
                      color={linkColor}
                      onPress={() => handleClickCollectionButton(item.text ?? item.text ?? '')}
                    >
                      <span dangerouslySetInnerHTML={{ __html: item.text }} />
                    </Link>
                  : null,
                )}
              </div>
            </>
          : null}
          {cmsData?.voucher?.text && canShowAddVoucherMessage ?
            <p className="mb-0 mt-1 text-xs">{t('newsletter', 'auto.voucher.applied')}</p>
          : null}
        </div>
      </div>
      <div className="hidden w-full lab:flex lab:items-stretch">
        <Button onPress={() => store.send({ type: 'close' })} variant="solid" className="h-8 w-full px-2 text-sm font-bold uppercase lg:h-6 lg:text-xs">
          {t('generico', 'boton.cerrar', 'Close')}
        </Button>
      </div>
    </div>
  );
}

type NewsletterModalProps = {
  visible: boolean;
  onClose: () => void;
  nlOrigin: string;
  camperlab: boolean;
  cmsData: NewsletterCmsData;
};

export function NewsletterModal({ camperlab = false, cmsData }: NewsletterModalProps) {
  const {
    storeData,
    t,
    profileData: { emptyBagCollections },
  } = useI18n();
  const { country } = useLocale();
  const { query, asPath } = useRouter();
  const { newsletter } = useAppData();

  const step = useSelector(store, (state) => state.context.step);
  const isOpen = useSelector(store, (state) => state.context.open);
  const origin = useSelector(store, (state) => state.context.origin);
  const initialized = useSelector(store, (state) => state.context.init);

  const config: NewsletterConfig = {
    allowedUsers: cmsData?.allowedUsers ?? 'new',
    dataEnrichment: cmsData?.dataEnrichment ?? false,
    showVoucher: cmsData?.showVoucher ?? false,
    camperlab,
    campaign: getNextQueryValue(query.utm_campaign) ?? cmsData?.campaign?.name ?? null,
    origin,
    formColor:
      getDomainValue({ camperlab: false, camper: true, nnormal: true }) && cmsData?.style?.background !== undefined ?
        getContrastingColor(cmsData?.style?.background)
      : 'black',
  };
  const defaultColorClassName = step !== 'thanks' && config.formColor === 'white' ? 'text-inverted' : 'text-copy';

  if (!initialized) {
    store.send({
      type: 'init',
      pathname: asPath,
      ctas:
        Array.isArray(cmsData?.thanksStep?.ctas) ? cmsData.thanksStep.ctas
        : Array.isArray(emptyBagCollections) ? emptyBagCollections
        : [],
      campaign: config?.campaign?.toLowerCase() ?? newsletter?.title?.toLowerCase() ?? '',
      voucher: cmsData?.thanksStep?.voucher?.text,
    });
  }

  const handleOpen = useCallback(
    (origin?: string, config?: { direct: boolean }) => {
      if (!storeData) {
        store.send({ type: 'open', origin: origin ?? 'popup', direct: config?.direct ?? false });
      }
    },
    [storeData],
  );

  useEffect(() => {
    window.openNewsletterModal = handleOpen;
    return () => {
      window.openNewsletterModal = undefined;
    };
  }, [handleOpen]);

  const StepComponents: Record<Step, JSX.Element> = {
    initial: <InitialStep cmsData={cmsData?.emailStep} config={config} />,
    email: (
      <div className="flex flex-grow nnormal:sm:max-w-xl nnormal:lg:max-w-2xl">
        <EmailStep config={config} cmsData={cmsData?.emailStep} />
      </div>
    ),
    update: <UpdateStep config={config} cmsData={cmsData?.extraStep} />,
    thanks: <ThanksStep config={config} cmsData={cmsData?.thanksStep} />,
    subscribed: <ThanksStep config={config} cmsData={cmsData?.thanksStep} />,
  };

  if (getDomainValue({ camperlab: true, camper: false, nnormal: false })) {
    return (
      <>
        <DialogRoot
          isOpen={isOpen && step === 'initial'}
          onOpenChange={() => {
            store.send({ type: 'close' });
          }}
          isDismissable
          position="bottom-right"
        >
          <Dialog className="w-[371px]">
            <DialogHeader
              label={
                <span
                  dangerouslySetInnerHTML={{
                    __html:
                      cmsData?.emailStep?.title?.text ??
                      newsletter?.title ??
                      t('header.messages', `header.ribbon.01.${country.toLowerCase()}.title`, 'Camper Newsletter'),
                  }}
                />
              }
            />
            {StepComponents.initial}
          </Dialog>
        </DialogRoot>
        <DrawerRoot
          isOpen={isOpen && step !== 'initial'}
          onOpenChange={() => {
            store.send({ type: 'close' });
          }}
          isDismissable
        >
          <Drawer className="flex flex-col max-sm:w-full lg:w-[calc(25vw+10px)] lg:min-w-[340px]">
            <DrawerHeader className="px-2.5" label={t('generico', `camper.newsletter`, 'Camper Newsletter')} />

            <div className="flex flex-grow flex-col">{StepComponents[step]}</div>
          </Drawer>
        </DrawerRoot>
      </>
    );
  }

  return (
    <DialogRoot
      isOpen={isOpen}
      onOpenChange={() => {
        store.send({ type: 'close' });
      }}
      isDismissable
      className={''}
    >
      <Dialog
        className={`flex h-full min-h-[500px] flex-col p-10 shadow sm:min-w-[420px] camper:sm:w-[600px] ${defaultColorClassName}`}
        style={step !== 'thanks' ? cmsData?.style : undefined}
        aria-label={
          cmsData?.emailStep?.title?.text ?? newsletter?.title ?? t('header.messages', `header.ribbon.01.${country.toLowerCase()}.title`, 'Camper Newsletter')
        }
      >
        <DialogX />
        <div className="flex flex-grow flex-col camper:sm:max-w-lg">{StepComponents[step]}</div>
      </Dialog>
    </DialogRoot>
  );
}
