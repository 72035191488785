import React from 'react';
import {
  DateField as AriaDateField,
  type DateFieldProps as AriaDateFieldProps,
  DateInput as AriaDateInput,
  composeRenderProps,
  type DateInputProps,
  DateSegment,
  type DateValue,
  type ValidationResult,
} from 'react-aria-components';
import { Description, FieldError, Input, Label, fieldBorderStyles, fieldGroupStyles } from './field';
import { composeTailwindRenderProps, focusRing } from './component-utils';
import { tv } from 'tailwind-variants';

const segmentStyles = tv({
  base: 'inline rounded px-0.5 py-px caret-transparent outline outline-0 forced-color-adjust-none type-literal:px-0 forced-colors:text-[ButtonText]',
  variants: {
    color: {
      white: 'pl text-inverted placeholder-shown:text-inverted/80 focus:bg-clear/40 forced-colors:bg-[Highlight] forced-colors:text-[HighlightText]',
      black: 'text-copy placeholder-shown:text-copy/80 focus:bg-neutral-inverted/10 forced-colors:bg-[Highlight] forced-colors:text-[HighlightText]',
    },
    isPlaceholder: {
      true: 'italic',
      false: '',
    },
    isDisabled: {
      true: 'text-disabled forced-colors:text-[GrayText]',
    },
  },
});

const dateInputStyles = tv({
  extend: focusRing,
  base: 'flex h-8 items-center border px-2 lg:text-sm lab:lg:h-6',
  variants: {
    ...fieldBorderStyles.variants,
    isFocused: fieldBorderStyles.variants.isFocusWithin,
  },
});

const transparentInputStyles = tv({
  base: 'flex h-8 items-center border px-2 outline-none lg:text-sm lg:placeholder:text-sm',
  variants: {
    color: {
      black: 'border-transparent bg-neutral-inverted/5 focus:border-b-dark',
      white:
        'border-transparent bg-neutral/20 text-inverted placeholder:text-inverted invalid:border-b-error focus:border-b-clear invalid:forced-colors:border-[Mark]',
    },
    isDisabled: {
      true: '!border-transparent !bg-disabled !text-disabled forced-colors:border-[GrayText]',
    },
    isFocused: {
      true: '',
      false: '',
    },
  },
});

type DateProps = Omit<DateInputProps, 'children'> & {
  variant?: 'default' | 'transparent';
  color?: 'white' | 'black';
};

export function DateInput({ variant = 'transparent', color = 'black', className, ...props }: DateProps) {
  return (
    <AriaDateInput
      {...props}
      className={composeRenderProps(className, (cl, renderProps) =>
        variant === 'transparent' ? transparentInputStyles({ color, className: cl, ...renderProps }) : dateInputStyles({ className: cl, ...renderProps }),
      )}
    >
      {(segment) => (
        <DateSegment segment={segment} className={composeRenderProps('', (className, renderProps) => segmentStyles({ className, ...renderProps, color }))} />
      )}
    </AriaDateInput>
  );
}

export interface DateFieldProps<T extends DateValue> extends AriaDateFieldProps<T> {
  label: string;
  hiddenLabel?: boolean;
  description?: string;
  errorMessage?: string | ((validation: ValidationResult) => string);
  dateProps?: Omit<DateInputProps, 'children'> & { variant?: 'default' | 'transparent'; color?: 'white' | 'black' };
}

export function DateField<T extends DateValue>({ label, description, errorMessage, dateProps, hiddenLabel, ...props }: DateFieldProps<T>) {
  return (
    <AriaDateField {...props} className={composeTailwindRenderProps(props.className, 'flex flex-col gap-2')}>
      <Label className={hiddenLabel ? 'sr-only' : ''}>{label}</Label>
      <DateInput {...dateProps} />
      {description && <Description>{description}</Description>}
      <FieldError className={`text-xs ${dateProps?.color === 'white' ? 'text-inverted' : 'text-error'}`}>{errorMessage}</FieldError>
    </AriaDateField>
  );
}
