import React from 'react';
import {
  Select as AriaSelect,
  type SelectProps as AriaSelectProps,
  Button,
  composeRenderProps,
  ListBox,
  type ListBoxItemProps,
  type SelectRenderProps,
  SelectValue,
  type ValidationResult,
} from 'react-aria-components';
import { tv } from 'tailwind-variants';
import { Description, FieldError, Label } from './field';
import { DropdownItem, DropdownSection, type DropdownSectionProps } from './list-box';
import { Popover } from './popover';
import { composeTailwindRenderProps, focusRing } from './component-utils';
import { LabChevronDownIcon } from 'components/camperlab/icons/chevron-down';
import { getDomainValue } from 'utils/domain';
import { ChevronSVG } from 'components/iconsSVG/chevron';

const styles = tv({
  extend: focusRing,
  base: 'flex h-8 w-full cursor-default items-center justify-between gap-4 border px-1.5 text-start text-sm transition lg:h-6 lg:text-xs',
  variants: {
    variant: {
      dark: 'border-dark enabled:group-invalid:border-error disabled:border-disabled disabled:text-disabled lab:has-[span[data-placeholder=true]]:border-dark lab:disabled:border-subtle lab:disabled:text-disabled enabled:forced-colors:group-invalid:border-[Mark] disabled:forced-colors:border-[GrayText] disabled:forced-colors:text-[GrayText]',
      default:
        'min-w-[150px] border-dark enabled:group-invalid:border-error disabled:border-disabled disabled:text-disabled lab:has-[span[data-placeholder=true]]:border-subtle lab:disabled:border-subtle lab:disabled:text-disabled enabled:forced-colors:group-invalid:border-[Mark] disabled:forced-colors:border-[GrayText] disabled:forced-colors:text-[GrayText]',
      empty:
        'lab:has-[span[data-placeholder=true]]:text-bold gap-1 border-transparent px-0 enabled:group-invalid:text-error disabled:bg-disabled/10 disabled:text-disabled disabled:forced-colors:text-[GrayText]',
    },
  },
});

const transparentStyles = tv({
  base: 'flex h-8 w-full cursor-default items-center gap-4 border border-transparent px-1.5 text-start text-sm outline-none transition lab:lg:h-6 lab:lg:text-xs',
  variants: {
    color: {
      black: 'border-transparent bg-neutral-inverted/5 focus:border-b-dark',
      white:
        'border-transparent bg-neutral/20 text-inverted placeholder:text-inverted invalid:border-b-error focus:border-b-clear invalid:forced-colors:border-[Mark]',
    },
    isDisabled: {
      true: '!border-transparent !bg-disabled !text-disabled forced-colors:border-[GrayText]',
    },
    isOpen: {},
    isInvalid: {},
    isRequired: {},
  },
});

export interface SelectProps<T extends object> extends Omit<AriaSelectProps<T>, 'children'> {
  label?: string;
  hiddenLabel?: boolean;
  variant?: 'default' | 'transparent' | 'dark' | 'empty';
  color?: 'white' | 'black';
  selectClassName?: string;
  description?: string;
  errorMessage?: string | ((validation: ValidationResult) => string);
  items?: Iterable<T>;
  children: React.ReactNode | ((item: T) => React.ReactNode);
}

export function Select<T extends object>({
  label,
  hiddenLabel,
  selectClassName,
  description,
  errorMessage,
  children,
  items,
  variant = 'default',
  color = 'black',
  ...props
}: SelectProps<T>) {
  const chevronClassName =
    'size-3.5 text-inherit transition group-open:rotate-180 group-disabled:text-disabled forced-colors:text-[ButtonText] forced-colors:group-disabled:text-[GrayText]';
  return (
    <AriaSelect {...props} className={composeTailwindRenderProps(props.className, 'group flex flex-col gap-1')}>
      {label && <Label className={hiddenLabel ? 'sr-only' : ''}>{label}</Label>}
      <Button
        className={composeRenderProps(props.className, (className, renderProps: any) => {
          if (variant === 'transparent') {
            return transparentStyles({ ...renderProps, className, color });
          }
          return styles({ ...renderProps, variant, className });
        })}
      >
        <SelectValue
          className={
            selectClassName ||
            `${variant !== 'dark' ? 'data-[placeholder=true]:text-bold' : 'flex-grow uppercase data-[placeholder=true]:text-copy'} text-sm lg:text-xs`
          }
        />

        {getDomainValue({
          camper: <ChevronSVG aria-hidden className={chevronClassName} />,
          nnormal: <ChevronSVG aria-hidden className={chevronClassName} />,
          camperlab: <LabChevronDownIcon aria-hidden className={chevronClassName} />,
        })}
      </Button>
      {description && <Description>{description}</Description>}
      <FieldError>{errorMessage}</FieldError>
      <Popover crossOffset={0} className="min-w-[--trigger-width]">
        <ListBox items={items} className={`max-h-[inherit] overflow-auto outline-none ${variant === 'dark' ? 'bg-neutral' : ''}`}>
          {children}
        </ListBox>
      </Popover>
    </AriaSelect>
  );
}

export function SelectItem(props: ListBoxItemProps) {
  return <DropdownItem {...props} />;
}

export function SelectSection<T extends object>(props: DropdownSectionProps<T>) {
  return <DropdownSection {...props} />;
}
