import { composeRenderProps, Link as RACLink, type LinkProps as RACLinkProps } from 'react-aria-components';
import { btnStyles, type ButtonProps } from './button';

export type LinkProps = RACLinkProps & {
  variant?: ButtonProps['variant'];
  color?: ButtonProps['color'];
  id?: string;
};

export function Link({ variant = 'text', color, className, ...rest }: LinkProps) {
  return (
    <RACLink
      {...rest}
      className={
        variant === 'none' ? className : composeRenderProps(className, (className, renderProps) => btnStyles({ ...renderProps, variant, color, className }))
      }
    />
  );
}
