import React, { useId, type ReactNode } from 'react';
import {
  Checkbox as AriaCheckbox,
  CheckboxGroup as AriaCheckboxGroup,
  type CheckboxGroupProps as AriaCheckboxGroupProps,
  type CheckboxProps as AriaCheckboxProps,
  type ValidationResult,
  composeRenderProps,
} from 'react-aria-components';
import { tv } from 'tailwind-variants';
import { Description, FieldError, Label } from './field';
import { composeTailwindRenderProps, focusRing } from './component-utils';
import { LabMinusIcon } from 'components/camperlab/icons/minus';
import { getDomainValue } from 'utils/domain';
import { CheckSVG } from 'components/iconsSVG/check';

export interface CheckboxGroupProps extends Omit<AriaCheckboxGroupProps, 'children'> {
  label?: string;
  children?: ReactNode;
  description?: string;
  errorMessage?: string | ((validation: ValidationResult) => string);
}

export function CheckboxGroup(props: CheckboxGroupProps) {
  return (
    <AriaCheckboxGroup {...props} className={composeTailwindRenderProps(props.className, 'flex flex-col gap-2')}>
      <Label>{props.label}</Label>
      {props.children}
      {props.description && <Description>{props.description}</Description>}
      <FieldError>{props.errorMessage}</FieldError>
    </AriaCheckboxGroup>
  );
}

const checkboxStyles = tv({
  base: 'group flex items-start gap-1.5 text-sm transition lab:lg:text-xs',
  variants: {
    color: {
      white: 'text-inverted',
      black: 'text-copy',
    },
    isDisabled: {
      false: 'text-copy',
      true: 'text-disabled forced-colors:text-[GrayText]',
    },
  },
});

const boxStyles = tv({
  extend: focusRing,
  base: 'mt-0.5 flex size-4 flex-shrink-0 items-center justify-center border p-0.5 text-sm transition lab:size-3',
  variants: {
    color: {
      white:
        'text-copy [--color:theme(textColor.inverted)] group-invalid:[--color:theme(textColor.error)] group-disabled:[--color:theme(textColor.disabled)] invalid:forced-colors:![--color:Mark] disabled:forced-colors:![--color:GrayText]',
      black:
        'nvalid:bg-error text-inverted [--color:theme(textColor.copy)] group-invalid:[--color:theme(textColor.error)] group-disabled:[--color:theme(textColor.disabled)] invalid:forced-colors:![--color:Mark] disabled:forced-colors:![--color:GrayText]',
    },
    isSelected: {
      false: 'border-[--color] bg-transparent',
      true: 'border-[--color] bg-[--color] forced-colors:![--color:Highlight]',
    },
  },
});

type CheckboxProps = Omit<AriaCheckboxProps, 'children'> & {
  boxClassName?: string;
  color?: 'white' | 'black';
  children: ReactNode;
};

export function Checkbox({ boxClassName, color = 'black', children, ...props }: CheckboxProps) {
  const id = useId();
  return (
    <div className={`flex items-start gap-2 ${props.className ?? ''}`}>
      <AriaCheckbox
        {...props}
        style={undefined}
        id={id}
        className={composeRenderProps('', (className, renderProps) => checkboxStyles({ ...renderProps, color, className }))}
      >
        {({ isSelected, isIndeterminate, ...renderProps }) => (
          <>
            <div className={boxStyles({ isSelected: isSelected || isIndeterminate, color, ...renderProps, className: boxClassName })}>
              {isSelected && getDomainValue({ camper: true, nnormal: true, camperlab: false }) ?
                <CheckSVG className="text-inherit" />
              : null}

              {isIndeterminate ?
                <LabMinusIcon aria-hidden className="h-full w-full text-copy group-disabled:text-disabled lg:text-sm forced-colors:text-[HighlightText]" />
              : null}
            </div>
          </>
        )}
      </AriaCheckbox>
      <label htmlFor={id} className="inline-flex">
        {children}
      </label>
    </div>
  );
}
