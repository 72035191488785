import React from 'react';
import { TextField as AriaTextField, type TextFieldProps as AriaTextFieldProps, type ValidationResult } from 'react-aria-components';
import { Description, FieldError, Input, Label, type InputProps } from './field';
import { composeTailwindRenderProps } from './component-utils';

export interface TextFieldProps extends AriaTextFieldProps {
  label: string;
  hiddenLabel?: boolean;
  description?: string;
  errorMessage?: string | ((validation: ValidationResult) => string);
  inputProps?: InputProps;
}

export function TextField({ label, description, errorMessage, inputProps, hiddenLabel, ...props }: TextFieldProps) {
  return (
    <AriaTextField {...props} className={composeTailwindRenderProps(props.className, 'flex flex-col gap-2')}>
      <Label className={hiddenLabel ? 'sr-only' : ''}>{label}</Label>
      <Input {...inputProps} />
      {description && <Description>{description}</Description>}
      <FieldError className={`text-xs ${inputProps?.color === 'white' ? 'text-inverted' : 'text-error'}`}>{errorMessage}</FieldError>
    </AriaTextField>
  );
}
