import { CalendarDate } from '@internationalized/date';

const isDateInputDate = (date: string): boolean => {
  return /^\d{4}-\d{2}-\d{2}$/.test(date);
};

export function toISODate(dateString: unknown) {
  try {
    if (typeof dateString !== 'string') {
      throw new Error('Invalid date string');
    }
    if (!isDateInputDate(dateString)) {
      throw new Error('Invalid date string');
    }
    const date = new Date(dateString);
    return date.toISOString();
  } catch (error) {
    console.error(error);
    return null;
  }
}

/**
 * Si no pasamos prop asumimos que es la fecha actual
 */
export function getCalendarDate(date?: Date) {
  const dateToUse = date ?? new Date();
  return new CalendarDate(dateToUse.getFullYear(), dateToUse.getMonth() + 1, dateToUse.getDate());
}
