import { isObject } from './assertions';

// Podemos usar este fichero para migrar las funciones de helpers.js que queramos sin cambiar ese fichero a typescript de un solo golpe
export function sleep(ms = 800) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function deleteEmptyKeys(obj: unknown) {
  if (!isObject(obj)) {
    // console.error('removeEmptyKeys: obj is not an object');
    return null;
  }
  // recorremos el objecto y si la propiedad es un objecto, llamamos a la función recursivamente, si es un array lo recorremos y si es un string lo comprobamos
  for (const key in obj) {
    if (isObject(obj[key])) {
      deleteEmptyKeys(obj[key]);
    } else if (Array.isArray(obj[key])) {
      obj[key].forEach((item) => {
        deleteEmptyKeys(item);
      });
    } else if (typeof obj[key] === 'string' && obj[key].trim() === '') {
      delete obj[key];
    }
  }
  return obj;
}

export function getNextQueryValue(value: string | string[] | undefined) {
  if (value === undefined) return undefined;

  if (Array.isArray(value)) {
    value = value.join(',');
  }
  value = decodeURIComponent(value);
  if (typeof value === 'string' && value.trim() === '') return undefined;
  return value;
}
