import { type ReactNode } from 'react';
import {
  OverlayArrow,
  Popover as AriaPopover,
  type PopoverProps as AriaPopoverProps,
  composeRenderProps,
  PopoverContext,
  useSlottedContext,
} from 'react-aria-components';
import { tv } from 'tailwind-variants';

export interface PopoverProps extends Omit<AriaPopoverProps, 'children'> {
  showArrow?: boolean;
  children: ReactNode;
}

const styles = tv({
  base: 'group border border-dark bg-clear bg-clip-padding forced-colors:bg-[Canvas]',
  variants: {
    isEntering: {
      true: 'duration-200 ease-out animate-in fade-in placement-left:slide-in-from-right-1 placement-right:slide-in-from-left-1 placement-top:slide-in-from-bottom-1 placement-bottom:slide-in-from-top-1',
    },
    isExiting: {
      true: 'duration-150 ease-in animate-out fade-out placement-left:slide-out-to-right-1 placement-right:slide-out-to-left-1 placement-top:slide-out-to-bottom-1 placement-bottom:slide-out-to-top-1',
    },
  },
});

export function Popover({ children, showArrow, className, offset: defaultOffset, ...props }: PopoverProps) {
  const popoverContext = useSlottedContext(PopoverContext)!;

  const isSubmenu = popoverContext?.trigger === 'SubmenuTrigger';
  let offset = showArrow ? 10 : (defaultOffset ?? 5);
  offset = isSubmenu ? offset - 5 : offset;

  return (
    <AriaPopover offset={offset} {...props} className={composeRenderProps(className, (className, renderProps) => styles({ ...renderProps, className }))}>
      {showArrow && (
        <OverlayArrow className="group flex">
          <svg
            width={12}
            height={12}
            viewBox="0 0 12 12"
            className="block fill-inverted stroke-subtle stroke-1 group-placement-left:-rotate-90 group-placement-right:rotate-90 group-placement-bottom:rotate-180 forced-colors:fill-[Canvas] forced-colors:stroke-[ButtonBorder]"
          >
            <path d="M0 0 L6 6 L12 0" />
          </svg>
        </OverlayArrow>
      )}
      {children}
    </AriaPopover>
  );
}
