import React, { type ReactNode } from 'react';
import {
  composeRenderProps,
  Radio as RACRadio,
  RadioGroup as RACRadioGroup,
  type RadioGroupProps as RACRadioGroupProps,
  type RadioProps as RACRadioProps,
  type ValidationResult,
} from 'react-aria-components';
import { tv } from 'tailwind-variants';
import { Description, FieldError, Label } from './field';
import { composeTailwindRenderProps, focusRing } from './component-utils';

export interface RadioGroupProps extends Omit<RACRadioGroupProps, 'children'> {
  label: string;
  hiddenLabel?: boolean;
  children?: ReactNode;
  description?: string;
  errorMessage?: string | ((validation: ValidationResult) => string);
}

export function RadioGroup({ hiddenLabel, ...props }: RadioGroupProps) {
  return (
    <RACRadioGroup {...props} className={composeTailwindRenderProps(props.className, 'group flex flex-col gap-2')}>
      <Label className={hiddenLabel ? 'sr-only' : ''}>{props.label}</Label>
      <div className="flex gap-2 group-orientation-horizontal:gap-4 group-orientation-vertical:flex-col">{props.children}</div>
      {props.description && <Description>{props.description}</Description>}
      <FieldError>{props.errorMessage}</FieldError>
    </RACRadioGroup>
  );
}

const indicator = tv({
  base: 'flex h-full w-full rounded-full bg-[var(--color)] transition-opacity',
  variants: {
    isSelected: {
      false: 'opacity-0',
      true: 'opacity-100',
    },
    isDisabled: {
      true: 'bg-disabled forced-colors:bg-[GrayText]',
    },
  },
});

const radioStyles = tv({
  base: 'full flex size-5 items-center justify-center rounded-full border border-[var(--color)] bg-transparent p-0.5 outline-none lab:size-2 lab:p-0 forced-colors:outline-[Highlight]',
  variants: {
    isFocusVisible: {
      false: '',
      true: 'ring-1 ring-[var(--color)] ring-opacity-60 lab:ring-1 lab:ring-opacity-100 lab:ring-offset-2 lab:ring-offset-neutral',
    },
  },
});

type RadioProps = RACRadioProps & {
  color?: 'white' | 'black';
};

export function Radio({ color = 'black', ...props }: RadioProps) {
  return (
    <RACRadio
      {...props}
      className={composeTailwindRenderProps(
        props.className,
        `group flex items-center gap-2 text-sm transition disabled:text-disabled lab:lg:text-xs forced-colors:disabled:text-[GrayText] ${color === 'white' ? '[--color:theme("textColor.inverted")]' : '[--color:theme("textColor.copy")]'}`,
      )}
    >
      {(renderProps) => (
        <>
          <div className={radioStyles(renderProps)}>
            <div className={indicator(renderProps)} />
          </div>
          {props.children}
        </>
      )}
    </RACRadio>
  );
}
