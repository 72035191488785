import React from 'react';
import {
  type FieldErrorProps as RACFieldErrorProps,
  Group,
  type GroupProps,
  type InputProps as RACInputProps,
  type LabelProps,
  FieldError as RACFieldError,
  Input as RACInput,
  Label as RACLabel,
  Text,
  type TextProps,
  composeRenderProps,
} from 'react-aria-components';
import { twMerge } from 'tailwind-merge';
import { tv } from 'tailwind-variants';
import { composeTailwindRenderProps, focusRing } from './component-utils';

export function Label(props: LabelProps) {
  return <RACLabel {...props} className={twMerge('w-fit cursor-default text-sm lab:font-bold lab:lg:text-xs', props.className)} />;
}

export function Description(props: TextProps) {
  return <Text {...props} slot="description" className={twMerge('text-sm text-muted', props.className)} />;
}

type FieldErrorProps = RACFieldErrorProps & {
  color?: 'white' | 'default';
};
export function FieldError({ color, ...props }: FieldErrorProps) {
  return (
    <RACFieldError
      {...props}
      className={composeTailwindRenderProps(props.className, `${color === 'white' ? 'text-inverted' : 'text-error'} lg:text-sm forced-colors:text-[Mark]`)}
    />
  );
}

export const fieldBorderStyles = tv({
  variants: {
    isFocusWithin: {
      false: 'border-dark placeholder-shown:border-subtle forced-colors:border-[ButtonBorder]',
      true: 'border-dark placeholder-shown:border-dark forced-colors:border-[Highlight]',
    },
    isInvalid: {
      true: '!border-error forced-colors:border-[Mark]',
    },
    isDisabled: {
      true: '!border-disabled placeholder-shown:border-disabled lab:!border-subtle lab:placeholder-shown:border-subtle forced-colors:border-[GrayText]',
    },
  },
});

export const fieldGroupStyles = tv({
  // extend: focusRing,
  base: 'group flex h-9 items-center overflow-hidden border-2 bg-neutral forced-colors:bg-[Field]',
  variants: fieldBorderStyles.variants,
});

export function FieldGroup(props: GroupProps) {
  return <Group {...props} className={composeRenderProps(props.className, (className, renderProps) => fieldGroupStyles({ ...renderProps, className }))} />;
}

const defaultInputStyles = tv({
  extend: focusRing,
  base: 'h-8 border px-2 lg:text-sm lab:lg:h-6',
  variants: {
    ...fieldBorderStyles.variants,
    isFocused: fieldBorderStyles.variants.isFocusWithin,
  },
});

const transparentInputStyles = tv({
  base: 'h-8 border px-2 outline-none placeholder:text-sm lg:text-sm',
  variants: {
    color: {
      black: 'border-transparent bg-neutral-inverted/5 focus:border-b-dark',
      white:
        'border-transparent bg-neutral/20 text-inverted placeholder:text-inverted invalid:border-b-error focus:border-b-clear invalid:forced-colors:border-[Mark]',
    },
    isDisabled: {
      true: '!border-transparent !bg-disabled !text-disabled forced-colors:border-[GrayText]',
    },
  },
});

export type InputProps = RACInputProps & {
  variant?: 'default' | 'transparent';
  color?: 'white' | 'black';
};
export function Input({ variant, color = 'black', ...props }: InputProps) {
  return (
    <RACInput
      {...props}
      className={composeRenderProps(props.className, (className, renderProps) => {
        return variant === 'transparent' ? transparentInputStyles({ ...renderProps, color, className }) : defaultInputStyles({ ...renderProps, className });
      })}
    />
  );
}
