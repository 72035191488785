export function colorToHSL(color?: string): string {
  if (!color) {
    return 'hsl(0, 0%, 100%)'; // Return white if color is undefined
  }

  // Convert hex to RGB
  let r: number, g: number, b: number;
  if (color.startsWith('#')) {
    color = color.slice(1);
    if (color.length === 3) {
      color = color
        .split('')
        .map((c) => c + c)
        .join('');
    }
    r = parseInt(color.substring(0, 2), 16);
    g = parseInt(color.substring(2, 4), 16);
    b = parseInt(color.substring(4, 6), 16);
  } else {
    return 'hsl(0, 0%, 100%)'; // Return white if color format is unsupported
  }

  // Convert RGB to HSL
  r /= 255;
  g /= 255;
  b /= 255;
  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);
  let h: number, s: number, l: number;
  l = (max + min) / 2;

  if (max === min) {
    h = s = 0; // achromatic
  } else {
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
      default:
        h = 0;
    }
    h /= 6;
  }

  h = Math.round(h * 360);
  s = Math.round(s * 100);
  l = Math.round(l * 100);

  return `hsl(${h}, ${s}%, ${l}%)`;
}

export function getContrastingColor(color?: string) {
  const hsl = colorToHSL(color);
  const [h, s, l] = hsl
    .replace('hsl(', '')
    .replace(')', '')
    .split(',')
    .map((value) => parseFloat(value));

  // Calculate the luminance
  const luminance = l / 100;

  // Return black or white depending on the luminance
  return luminance > 0.5 ? 'black' : 'white';
}
